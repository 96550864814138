import React from "react";
import Card from "components/Cards/Card";
import ConfigForm from "components/Forms/ConfigForm";
import ConfigField from "components/Forms/ConfigField";
import SubmitButton from "components/Forms/SubmitButton";
import Layout from "layouts/EmptyLayout";
import { redirect } from "libs/utils";

const form = {
  name: "contact",
  submitName: "Contact",
  //className: "grid grid-cols-1 lg:grid-cols-2 gap-4",
  action: "",
  onSuccess: () => {
    redirect("/thankyou");
  },
  fields: [
    {
      type: "input",
      subType: "email",
      name: "email",
      label: "Email",
      placeholder: "Your email",
      rules: {
        required: true,
        minLength: { value: 5 },
      },
    },
    {
      type: "input",
      subType: "string",
      name: "name",
      label: "Full name",
      placeholder: "Your name",
      defaultValue: "",
      rules: {
        required: true,
        minLength: { value: 5 },
      },
    },
    {
      type: "input",
      subType: "hidden",
      name: "form-name",
      defaultValue: "contact",
      value: "contact",
    },
    {
      type: "textarea",
      name: "message",
      label: "Message",
      placeholder: "Your message",
      rules: {
        required: true,
      },
      rows: 5,
    },
  ],
};

export default function Contact() {
  return (
    <>
      <Layout title="Contact">
        <div className="container mx-auto px-4 h-full">
          <div className="flex content-center items-center justify-center h-full mt-6">
            <div className="w-full lg:w-8/12 p-4">
              <div className="pb-4 lg:pb-10">
                <h1 className="w-full flex m-6 capitalize text-4xl justify-center">
                  {form.name}
                </h1>

                <p className="leading-relaxed mt-1 mb-4"></p>
              </div>

              <Card className="bg-background-300">
                <ConfigForm {...form} data-netlify="true" />
              </Card>
            </div>
          </div>

          <div className="flex content-center items-center justify-center h-full mt-6">
            <div className="w-full lg:w-8/12 p-4">
              <Card title={form.name} className="bg-background-300">
                <form name="contact" data-netlify="true" method="POST">
                  {form.fields.map((field, index) => {
                    return <ConfigField key={index} config={field} />;
                  })}
                  <div className="w-full">
                    <SubmitButton className="w-full">
                      {form.submitName}
                    </SubmitButton>
                  </div>
                </form>
              </Card>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}
