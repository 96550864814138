import React, { ReactNode } from "react";

type Props = {
  children: ReactNode;
  className?: string;
  title?: string;
};

export default function Card({ children, className = "", title }: Props) {
  return (
    <div
      className={`relative flex flex-col break-words w-full p-2 md:p-4 shadow-lg rounded-lg ${className}`}
    >
      {title && (
        <div className="w-full text-center capitalize pb-4">
          <h3 className="text-3xl">{title}</h3>
        </div>
      )}
      <div className="w-full px-2 lg:px-4 pb-2">{children}</div>
    </div>
  );
}
